import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { Link as Anchor } from "react-scroll";
import Sticky from "react-stickynode";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BookModal from "../components/book-modal";
import ServicesMap from "../components/services-map";

const SpiritTemplate = ({ data, pageContext: context }) => {
  const {
    title,
    main_title,
    subtitle,
    introduction,
    rooms,
    breakfasts,
    bonuses,
    footer,
  } = data.contentfulChambresEtServicesPage ?? {};

  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h3 className="h2">{children}</h3>
      ),
    },
  };

  const intl = useIntl();

  const params = new URLSearchParams({
    id_etab: data.contentfulHotel.booking_id,
    langue: intl.locale === "fr" ? "francais" : "anglais",
  }).toString();

  const url = `https://reservation.ace-hotel.com/module_booking_engine/index.php?${params}`;

  function book(event) {
    event.preventDefault();

    const url = event.target.href;

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "begin_checkout",
        event_category: "outbound",
        eventCallback: () => {
          window.open(url, "_blank");
        },
        eventTimeout: 2000,
      });
    } else {
      window.open(url, "_blank");
    }
  }

  function getPage(category) {
    return allPages.find((page) => page.category === category);
  }

  return (
    <Layout>
      <Seo
        title={title}
        description={data.contentfulEsprit.short_description}
        image={
          data.contentfulEsprit.thumbnail &&
          data.contentfulEsprit.thumbnail.file.url
        }
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "${getPage("Esprits").title}",
                  "item": "${siteUrl}/${context.locale}/${
            getPage("Esprits").slug
          }/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${title}"
                }
              ]
            }
          `}
        </script>
      </Seo>
      <article>
        <section className="section section-white pb-8">
          <div className="introduction introduction-clouds">
            <div className="introduction-container">
              <div className="introduction-inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <p className="eyebrow">{title}</p>
                      <h1>{main_title}</h1>
                      <p className="lead">{subtitle}</p>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Sticky className="anchors" innerZ={1020} bottomBoundary=".content">
          <nav>
            <ul>
              <li>
                <Anchor
                  activeClass="active"
                  to="nos-chambres"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <FormattedMessage id="spirit.rooms" />
                </Anchor>
              </li>
              <li>
                <Anchor
                  activeClass="active"
                  to="les-petits-dejeuners"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <FormattedMessage id="spirit.breakfasts" />
                </Anchor>
              </li>
              <li>
                <Anchor
                  activeClass="active"
                  to="les-petits-plus"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <FormattedMessage id="spirit.extras" />
                </Anchor>
              </li>
              <li>
                <Anchor
                  activeClass="active"
                  to="acces-et-services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <FormattedMessage id="map.access" />
                </Anchor>
              </li>
            </ul>
          </nav>
        </Sticky>
        <section className="section section-white pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {data.contentfulEsprit.logo && (
                  <div className="d-flex justify-content-center absolute mb-10">
                    <Img
                      className="logo"
                      fluid={data.contentfulEsprit.logo.fluid}
                      alt={data.contentfulEsprit.title}
                    />
                  </div>
                )}
                {introduction &&
                  renderRichText(introduction, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <p className="lead">{children}</p>
                      ),
                    },
                  })}
              </div>
            </div>
          </div>
        </section>
        <section
          className="section section-gradient pt-16 pt-md-30"
          id="nos-chambres"
        >
          <div className="container">
            <h2
              className={`paragraph text-center mb-12 mb-md-20 ${
                data.contentfulHotel.spirit.slug === "travel"
                  ? "text-orange"
                  : "text-yellow"
              }`}
            >
              <FormattedMessage id="spirit.rooms" />
            </h2>
            {rooms?.map((node, i) => (
              <div
                className={
                  node.thumbnail ? "columns" : "columns columns-reverse"
                }
                key={i}
              >
                {node.thumbnail && (
                  <div className="columns-item columns-item-image">
                    <Img
                      fluid={{
                        ...node.thumbnail.fluid,
                        aspectRatio: 460 / 610,
                      }}
                      alt={node.thumbnail.title}
                    />
                  </div>
                )}
                <div
                  className={`columns-item${
                    node.thumbnail
                      ? " columns-item-text"
                      : " columns-item-center"
                  }`}
                >
                  {renderRichText(node.content, renderOptions)}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="section section-gradient" id="les-petits-dejeuners">
          <div className="container">
            <h2
              className={`paragraph text-center mb-12 mb-md-20 ${
                data.contentfulHotel.spirit.slug === "travel"
                  ? "text-orange"
                  : "text-yellow"
              }`}
            >
              <FormattedMessage id="spirit.breakfasts" />
            </h2>
            {breakfasts?.map((node, i) => (
              <div
                className={
                  node.thumbnail ? "columns" : "columns columns-reverse"
                }
                key={i}
              >
                {node.thumbnail && (
                  <div className="columns-item columns-item-image">
                    <Img
                      fluid={{
                        ...node.thumbnail.fluid,
                        aspectRatio: 460 / 610,
                      }}
                      alt={node.thumbnail.title}
                    />
                  </div>
                )}
                <div
                  className={`columns-item${
                    node.thumbnail
                      ? " columns-item-text"
                      : " columns-item-center"
                  }`}
                >
                  {renderRichText(node.content, renderOptions)}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="section section-gradient" id="les-petits-plus">
          <div className="container">
            <h2
              className={`paragraph text-center mb-12 mb-md-20 ${
                data.contentfulHotel.spirit.slug === "travel"
                  ? "text-orange"
                  : "text-yellow"
              }`}
            >
              <FormattedMessage id="spirit.extras-extended" />
            </h2>
            {bonuses?.map((node, i) => (
              <div
                className={
                  node.thumbnail ? "columns" : "columns columns-reverse"
                }
                key={i}
              >
                {node.thumbnail && (
                  <div className="columns-item columns-item-image">
                    <Img
                      fluid={{
                        ...node.thumbnail.fluid,
                        aspectRatio: 460 / 610,
                      }}
                      alt={node.thumbnail.title}
                    />
                  </div>
                )}
                <div
                  className={`columns-item${
                    node.thumbnail
                      ? " columns-item-text"
                      : " columns-item-center"
                  }`}
                >
                  {renderRichText(node.content, renderOptions)}
                </div>
              </div>
            ))}
          </div>
        </section>
        {footer && (
          <section className="section section-blue text-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  {renderRichText(footer.content)}
                  <p className="mt-8 mt-md-10">
                    {data.contentfulHotel.booking_id ? (
                      <a className="button" href={url} onClick={book}>
                        <FormattedMessage id="book-hotel" />
                      </a>
                    ) : (
                      <BookModal />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
        <section
          className="section section-white overflow-hidden"
          id="acces-et-services"
        >
          <div className="search">
            <div className="search-container">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <h2 className="mb-4 mb-md-0">
                      <FormattedMessage id="map.services" />
                      {` ACE Hôtel${
                        data.contentfulHotel.spirit.slug === "travel"
                          ? " Travel"
                          : ""
                      } ${data.contentfulHotel.title}`}
                    </h2>
                  </div>
                </div>
              </div>
              <LazyLoad>
                <ServicesMap />
              </LazyLoad>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default SpiritTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      address
      lat
      lon
      slug
      phone
      booking_id
      place_id
      business_url
      radius
      hero_image {
        file {
          url
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulChambresEtServicesPage(
      hotel: { slug: { eq: $hotel } }
      spirit: { slug: { eq: $slug } }
      node_locale: { eq: $locale }
    ) {
      title
      main_title
      subtitle
      rooms {
        content {
          raw
        }
        thumbnail {
          fluid(maxHeight: 610, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
      breakfasts {
        content {
          raw
        }
        thumbnail {
          fluid(maxHeight: 610, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
      bonuses {
        content {
          raw
        }
        thumbnail {
          fluid(maxHeight: 610, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
      introduction {
        raw
      }
      footer {
        content {
          raw
        }
      }
    }
    contentfulEsprit(slug: { eq: $slug }) {
      title
      short_description
      logo {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      thumbnail {
        file {
          url
        }
      }
    }
    allContentfulPointDinteret(filter: { node_locale: { eq: $locale } }) {
      nodes {
        category
        place_id
        title
        vicinity
        lat
        lng
        hotel {
          title
          slug
        }
      }
    }
  }
`;
